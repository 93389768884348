import * as React from "react"

const Intro = () =>
    <div className="space-y-1 bg-blue-100">
        <div className="max-w-screen-lg m-auto pt-10 md:pt-20 pb-1 md:pb-12 px-5 md:px-10 md:flex justify-between">
            <div className="space-y-1 bg-blue-100 p-6 md:w-8/12 mb-10 md:mb-0">
                <div className="font-medium text-6xl mb-6 text-blue-900">
                    <a>Business is </a>
                    <a className="underline decoration-from-font"> always</a>
                    <a> about </a>
                    <a className="text-fuchsia-500"> people</a>

                </div>
                <p className="text-xl leading-normal pb-2">People are at the center of every business. Yet, many business decisions fail to understand and recognise the impact their customers, employees, and partners have on achieving outcomes.</p>
                <p className="text-xl leading-normal pb-2">At rephorm, we follow a human-centred design approach to demystify the people aspects of your business, helping you make better-informed decisions and achieve the outcomes you desire.</p>
            </div>
            {/*}            <div className="space-y-1 bg-white p-4 md:w-5/12">

                <img src="https://unsplash.com/photos/a6FNOYGWj6k" alt="temp impage" className="w-full"></img>
            </div>
*/}        </div>
    </div>

//md:flex justify-between py-20 px-10 bg-white
export default Intro;