import * as React from "react"
import EyeIcon from "../images/Eye icon.png"
import LightbulbIcon from "../images/Lightbulb icon.png"
import DesignIcon from "../images/Design icon.png"

const Services = () =>
    <div id="services" className="bg-blue-200">
        <div className="py-10 px-5 md:px-10 max-w-screen-lg m-auto">
            <h2 className="text-3xl text-gray-500 text-center tracking-normal mb-4">Our services</h2>
            <div className="space-y-4 md:space-y-0 bg-blue-200 p-5 md:flex justify-between content-start">
                <div className="bg-white rounded-lg shadow p-4 md:w-1/3 flex grow flex-col md:mr-5">
                    <img src={EyeIcon} alt="icon" className="h-12 w-14 mb-2"></img>
                    <h3 className="text-2xl text-blue-900 mb-2">Insight</h3>
                    <p className="flex-1">When you take the time to understand the people you're in business for and with, outcomes become more predictable and decision-making easier. We help provide this insight through qualitative and quantitative research and developing tools to aid you when making decisions.</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow md:w-1/3 flex grow flex-col md:mr-5">
                    <img src={LightbulbIcon} alt="icon" className="w-8 h-12 mb-2"></img>
                    <h3 className="text-2xl text-blue-900 mb-2">Innovation</h3>
                    <p className="flex-1">Powering creativity with insight is how the magic happens. Creative solutions that fail to satisfy people's needs will fall flat, damaging your reputation and costing you money. We help you innovate by bringing the human element into your design and decision-making practices.</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow flex flex-col grow md:w-1/3">
                    <img src={DesignIcon} alt="icon" className="h-12 w-12 mb-2"></img>
                    <h3 className="text-2xl text-blue-900 mb-2">Design</h3>
                    <p className="flex-1">Whether a great idea realises its potential depends on the skill and diligence with which it is brought to life. Even the best designs can go off track if they're not tested with their intended audience. We help you design, prototype, and test ideas to get them build-ready.</p>
                </div>
            </div>
        </div>
    </div>
export default Services;