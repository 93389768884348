import * as React from "react"

const Contact = () =>
    <div id="contact" className="space-y-1 bg-blue-100">
        <div className="max-w-screen-lg m-auto px-5 py-10 md:px-10 md:py-10">
            <h2 className="text-3xl text-gray-500 text-center tracking-normal mb-6">Get in touch</h2>
            <p className="text-xl leading-normal text-center pb-2">Whatever your challenge, we can help you design a human-centred solution. </p>
            <div className="flex md:p-5 justify-around">
                <div className="py-4 md:p-4 md:w-1/2 flex flex-col md:mr-5 text-center">
                    <a className="text-2xl text-blue-900 mb-2" href="mailto:si@rephorm.com.au" target="blank">email</a>
                    <a className="flex-1 text-lg" href="mailto:si@rephorm.com.au" target="blank">si@rephorm.com.au</a>
                </div>
                <div className="py-4 md:p-4 md:w-1/2 flex flex-col md:mr-5 text-center">
                    <a className="text-2xl text-blue-900 mb-2" href="https://www.linkedin.com/company/rephorm-creative/" target="blank">linkedIn</a>
                    <a className="flex-1 text-lg hover:text-blue-900" href="https://www.linkedin.com/company/rephorm-creative/" target="blank">rephorm-creative</a>
                </div>
            </div>
            {/*}
            <div className="md:space-y-0 bg-blue-200 p-5 md:flex justify-between content-start">
                <div className="p-4 md:w-1/2 flex flex-col md:mr-5">

                    <p className="flex-1">si@rephorm.com.au</p>
                </div>
                <div className="p-4 md:w-1/2 flex flex-col md:mr-5">

                    <p className="flex-1">0477 030077</p>
                </div>
            </div>
*/}
        </div>
    </div >

//                    <h3 className="text-2xl text-blue-900 mb-2">email</h3>
//md:flex justify-between py-20 px-10 bg-white
export default Contact;