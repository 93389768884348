import * as React from "react"
import Logo from "../images/Rephorm logo reversedSVG.svg"

/*
const HeaderNew = () =>
    <div className="min-h-screen p-20 bg-blue-400">
        <h2 className="text-xl text-gray-500 font-thin text-center tracking-widest">REPHORM PRESENTS</h2>
        <h1 className="text-7xl text-cyan-400 font-bold text-center">SIMON HAIGH</h1>
        <h3 className="text-2xl text-gray-500 font-regular text-center">Freelance Experience and Graphic Designer</h3>
        <h3 className="text-lg text-black font-medium text-center">si@rephorm.com.au</h3>
        <a className="text-lg text-cyan-600 font-medium block text-center" href="https://www.linkedin.com/in/sihaigh/" target="blank">LinkedIn profile</a>
    </div>
export default HeaderNew;
*/

const HeaderNew = () =>
    <div className="py-5 px-10 bg-blue-900 max-w-screen-lg m-auto flex justify-between items-center">
        {/* left side */}
        <div>
            <a href="https://www.rephorm.com.au">
                <img alt="Rephorm logo" src={Logo} className="h-14 md:h-16 w-auto" href="https://www.rephorm.com.au"></img>
            </a>
            {/* <img src="/images/Rephorm logo.png" alt="rephorm logo"></img> */}
        </div>
        {/* right side */}
        <div className="hidden md:block">
            <a href="#services" className="inline-block mr-10 text-gray-200 hover:text-white scroll-smooth">SERVICES</a>
            <a href="#clients" className="inline-block mr-10 text-gray-200 hover:text-white scroll-smooth">CLIENTS</a>
            <a href="#contact" className="inline-block text-gray-200 hover:text-white scroll-smooth">CONTACT</a>
        </div>
    </div>


export default HeaderNew;
