import * as React from "react"
import CBALogo from "../images/CBA logo.png"
import CommSecLogo from "../images/CommSec logo.png"
import WestpacLogo from "../images/Westpac logo.png"
import IngLogo from "../images/ING logo.png"
import VirginMoneyLogo from "../images/VirginMoney logo.png"
import AMPLogo from "../images/AMP logo.png"
import MLALogo from "../images/MLA logo.png"
//import ISCLogo from "../images/ISC logo.png"
import icareLogo from "../images/icare logo.png"
import SNSWLogo from "../images/SNSW logo.png"
import ABCLogo from "../images/ABC logo.png"
import CHallLogo from "../images/CH logo.png"
import QantasLogo from "../images/Qantas logo.png"
import BlueCareLogo from "../images/BlueCare logo.png"
import HumanLogo from "../images/Humans logo.png"
import SitbackLogo from "../images/Sitback logo.png"
import SymplicitLogo from "../images/Symplicit logo.png"
import LoweLogo from "../images/303Lowe logo.png"
import UDKULogo from "../images/UDKU logo.png"

const Clients = () =>
    // <div id="clients" className="space-y-1 bg-white">
    //     <div className="max-w-screen-lg m-auto p-10">
    //         <h2 className="text-3xl text-gray-500 text-center tracking-normal mb-8">Clients</h2>
    //         <div className="space-y-1 bg-white flex py-2 justify-around items-center">
    //             <img src={CBALogo} alt="CBA logo" className="h-16 md:h-20 w-auto mb-2"></img>
    //             <img src={CommSecLogo} alt="CommSec logo" className="h-16 md:h-20 w-auto mb-2"></img>
    //             <img src={VirginMoneyLogo} alt="VirginMoney logo" className="h-16 md:h-20 w-auto mb-2"></img>
    //             <img src={WestpacLogo} alt="Westpac logo" className="h-4 md:h-6 w-auto mb-2"></img>
    //         </div>
    //         <div className="space-y-1 bg-white flex py-5 justify-around items-center">
    //             <img src={AMPLogo} alt="AMP logo" className="h-12 md:h-16 w-auto mb-2"></img>
    //             <img src={IngLogo} alt="ING logo" className="h-16 md:h-20 w-auto mb-2"></img>
    //             <img src={QantasLogo} alt="Qantas logo" className="h-7 md:h-10 w-auto"></img>
    //         </div>
    //         <div className="space-y-1 bg-white flex py-5 justify-around items-center">
    //             <img src={ABCLogo} alt="ABC logo" className="h-6 md:h-8 w-auto"></img>
    //             <img src={SNSWLogo} alt="SNSW logo" className="h-10 md:h-14 w-auto"></img>
    //             <img src={icareLogo} alt="icare logo" className="h-7 md:h-10 w-auto"></img>
    //         </div>
    //         <div className="space-y-1 bg-white flex py-5 justify-around items-center">
    //             <img src={CHallLogo} alt="Charter Hall logo" className="h-6 md:h-8 w-auto"></img>
    //             <img src={MLALogo} alt="MLA logo" className="h-10 md:h-16 w-auto"></img>
    //             {/* <img src={ISCLogo} alt="ISC logo" className="h-16 w-auto"></img> */}
    //             <img src={BlueCareLogo} alt="BlueCare logo" className="h-10 md:h-16 w-auto"></img>
    //         </div >
    //         <div className="space-y-1 bg-white flex py-5 justify-around items-center">
    //             <img src={HumanLogo} alt="ISC logo" className="h-8 md:h-12 w-auto mb-2"></img>
    //             <img src={SitbackLogo} alt="ISC logo" className="h-8 md:h-12 w-auto mb-2"></img>
    //             <img src={SymplicitLogo} alt="ISC logo" className="h-6 md:h-8 w-auto mb-2"></img>
    //             <img src={UDKULogo} alt="ISC logo" className="h-10 md:h-16 w-auto mb-2"></img>
    //             <img src={LoweLogo} alt="ISC logo" className="h-7 md:h-10 w-auto mb-2"></img>
    //         </div>
    //     </div >
    // </div >

    <div id="clients" className="space-y-1 bg-white">
        <div className="max-w-screen-lg m-auto p-10">
            <h2 className="text-3xl text-gray-500 text-center tracking-normal mb-8">Clients</h2>
            <div className="space-y-1 bg-white flex flex-row flex-wrap justify-center items-center gap-8 md:gap-12">
                <img src={CBALogo} alt="CBA logo" className="h-16 md:h-20 w-auto mb-2"></img>
                <img src={CommSecLogo} alt="CommSec logo" className="h-16 md:h-20 w-auto mb-2"></img>
                <img src={VirginMoneyLogo} alt="VirginMoney logo" className="h-16 md:h-20 w-auto mb-2"></img>
                <img src={WestpacLogo} alt="Westpac logo" className="h-4 md:h-6 w-auto mb-2"></img>
                <img src={AMPLogo} alt="AMP logo" className="h-12 md:h-16 w-auto mb-2"></img>
                <img src={IngLogo} alt="ING logo" className="h-16 md:h-20 w-auto mb-2"></img>
                <img src={QantasLogo} alt="Qantas logo" className="h-7 md:h-10 w-auto"></img>
                <img src={ABCLogo} alt="ABC logo" className="h-6 md:h-8 w-auto"></img>
                <img src={SNSWLogo} alt="SNSW logo" className="h-10 md:h-14 w-auto"></img>
                <img src={icareLogo} alt="icare logo" className="h-7 md:h-10 w-auto"></img>
                <img src={CHallLogo} alt="Charter Hall logo" className="h-6 md:h-8 w-auto"></img>
                <img src={MLALogo} alt="MLA logo" className="h-10 md:h-16 w-auto"></img>
                <img src={BlueCareLogo} alt="BlueCare logo" className="h-10 md:h-16 w-auto"></img>
                <img src={HumanLogo} alt="ISC logo" className="h-8 md:h-12 w-auto mb-2"></img>
                <img src={SitbackLogo} alt="ISC logo" className="h-8 md:h-12 w-auto mb-2"></img>
                <img src={SymplicitLogo} alt="ISC logo" className="h-6 md:h-8 w-auto mb-2"></img>
                <img src={UDKULogo} alt="ISC logo" className="h-10 md:h-16 w-auto mb-2"></img>
                <img src={LoweLogo} alt="ISC logo" className="h-7 md:h-10 w-auto mb-2"></img>
            </div>
        </div>
    </div >

// <div className="space-y-1 bg-green-200">
//     <div className="max-w-screen-lg m-auto p-10">
//         <h2 className="text-3xl text-gray-500 text-center tracking-normal mb-8">Test</h2>
//         <div className="space-y-1 bg-green-100 flex-row flex-wrap justify-center space-x-4">
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={CBALogo} alt="CBA logo" className="h-16 md:h-20 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={CommSecLogo} alt="CommSec logo" className="h-16 md:h-20 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={VirginMoneyLogo} alt="VirginMoney logo" className="h-16 md:h-20 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={WestpacLogo} alt="Westpac logo" className="h-4 md:h-6 w-auto mb-2 inset-0"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={AMPLogo} alt="AMP logo" className="h-12 md:h-16 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={IngLogo} alt="ING logo" className="h-16 md:h-20 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={QantasLogo} alt="Qantas logo" className="h-7 md:h-10 w-auto"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={ABCLogo} alt="ABC logo" className="h-6 md:h-8 w-auto"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={SNSWLogo} alt="SNSW logo" className="h-10 md:h-14 w-auto"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={icareLogo} alt="icare logo" className="h-7 md:h-10 w-auto"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={CHallLogo} alt="Charter Hall logo" className="h-6 md:h-8 w-auto"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={MLALogo} alt="MLA logo" className="h-10 md:h-16 w-auto"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={BlueCareLogo} alt="BlueCare logo" className="h-10 md:h-16 w-auto"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={HumanLogo} alt="ISC logo" className="h-8 md:h-12 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={SitbackLogo} alt="ISC logo" className="h-8 md:h-12 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={SymplicitLogo} alt="ISC logo" className="h-6 md:h-8 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-300 inline-block">
//                 <img src={UDKULogo} alt="ISC logo" className="h-10 md:h-16 w-auto mb-2"></img>
//             </div>
//             <div className="h-20 bg-gray-400 inline-block">
//                 <img src={LoweLogo} alt="ISC logo" className="h-7 md:h-10 w-auto mb-2"></img>
//             </div>
//         </div>
//     </div>
// </div >

export default Clients;